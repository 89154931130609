import Intro from './views/Intro';

const routes = [
	{
		path: '/',
		exact: true,
		component: Intro
	}
];

export default routes;
